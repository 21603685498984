import React, { useState, useEffect } from "react";
import Slider from 'react-slick';
import "./Customers.css";

import THREEDIUM_LOGO from "../../assets/images/customer-logos/threedium.png";
import AIS_LOGO from "../../assets/images/customer-logos/ais.webp";
import EDILYTICS_LOGO from "../../assets/images/customer-logos/edilytics.jpeg";
import ERGOGENESIS_LOGO from "../../assets/images/customer-logos/ergo.webp";
import HBNS_LOGO from "../../assets/images/customer-logos/hbns.jpeg";
import HEWI_LOGO from "../../assets/images/customer-logos/hewi.png";
import MEDSTORK_LOGO from "../../assets/images/customer-logos/medstork.png";
import NEVOLI_LOGO from "../../assets/images/customer-logos/nevoli.png";
import ZOLL_LOGO from "../../assets/images/customer-logos/zoll.png";

const Customers = () => {
  const settings = {
    infinite: true,
    speed: 3500,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 6,
          dots: false,
          arrows: false
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          dots: false,
          arrows: false
        },
      },
    ],
  };
  return (
    <div id="customers">
      <div className="col-12 title">Trusted By</div>
      <div className="slider-holder">
        <Slider {...settings}>
          {customersData.map(data => (
            <div key={data.id}>
              <img className="customer-image" src={data.image} alt={data.name} />
            </div>
          ))}
        </Slider>
      </div>

    </div>
  );
};

const customersData = [
  { id: 1, image: THREEDIUM_LOGO },
  { id: 2, image: AIS_LOGO },
  { id: 3, image: EDILYTICS_LOGO },
  { id: 4, image: ERGOGENESIS_LOGO, name: "OculusHealth", url: "https://oculushealth.com" },
  { id: 5, image: HBNS_LOGO, name: "OculusHealth", url: "https://oculushealth.com" },
  { id: 6, image: HEWI_LOGO, name: "OculusHealth", url: "https://oculushealth.com" },
  { id: 7, image: MEDSTORK_LOGO, name: "OculusHealth", url: "https://oculushealth.com" },
  { id: 8, image: NEVOLI_LOGO, name: "OculusHealth", url: "https://oculushealth.com" },
  { id: 9, image: ZOLL_LOGO, name: "OculusHealth", url: "https://oculushealth.com" }
];

export default Customers;