import React from "react";
import Company from "../../components/company/Company";
import WhyChooseTysonWorks from "../../components/whychoosetysonworks/WhyChooseTysonWorks";
import CaseStudies from "../../components/casestudies/CaseStudies";
import ScrollReveal from '../../components/scroll/ScrollReveal';
import "./About.css";

const About = (props) => {
  return (
    <React.Fragment>
      <div id="about" className="main-content">
        <ScrollReveal>
          <Company />
        </ScrollReveal>
        <ScrollReveal delay={400}>
          <WhyChooseTysonWorks />
        </ScrollReveal>
        <ScrollReveal delay={200}>
          <CaseStudies />
        </ScrollReveal>
      </div>
    </React.Fragment>
  );
};

export default About;
