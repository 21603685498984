import React from "react";
import "./CaseStudies.css";

import MEDICHANDS_LOGO from "../../assets/images/medichands.png";
import OCULUSHEALTH_LOGO from "../../assets/images/oculushealth.png";
import ZIRAN_LOGO from "../../assets/images/ziran.png";

const CaseStudies = () => {
  const caseData = [
    {
      id: 0,
      image: OCULUSHEALTH_LOGO,
      name: "Oculus Health",
      client: "Healthcare",
      highlight: "45% Cost Reduction",
      description: "Streamlined HIPAA-compliant infrastructure and reduced monthly AWS costs from $25K to $13.7K while improving performance.",
      achievements: [
        "Implemented auto-scaling for 3x better response times",
        "Automated HIPAA compliance monitoring",
        "Reduced deployment time from 2 hours to 15 minutes"
      ]
    },
    {
      id: 1,
      image: MEDICHANDS_LOGO,
      name: "MedicHands",
      client: "Healthcare",
      highlight: "4x Faster Deployments",
      description: "Modernized legacy infrastructure with containerization and automated CI/CD, enabling rapid feature delivery.",
      achievements: [
        "Containerized 12 legacy applications",
        "99.99% uptime achievement",
        "Zero-downtime deployments implemented"
      ]
    },
    {
      id: 2,
      image: ZIRAN_LOGO,
      name: "Ziran Biopharmaceutical",
      client: "Biotechnology",
      highlight: "2x Performance Boost",
      description: "Optimized high-performance computing workloads and implemented cost-effective data storage solutions.",
      achievements: [
        "Reduced data processing time by 50%",
        "Implemented $15K/month cost savings",
        "Enhanced data security protocols"
      ]
    }
  ];

  return (
    <section id="case-studies">
      <div className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">Success Stories</h2>
          <p className="subtitle">See how we've helped other companies achieve their cloud goals</p>
        </div>
      </div>
      <div className="cases-grid">
        {caseData.map((caseItem) => (
          <CaseStudy key={caseItem.id} caseItem={caseItem} />
        ))}
      </div>
    </section>
  );
};

const CaseStudy = ({ caseItem }) => {
  const { image, name, client, description, highlight, achievements } = caseItem;

  return (
    <div className="case-study-card">
      <div className="logo-container">
        <img src={image} alt={name} />
      </div>
      <div className="content">
        <div className="header">
          <h3>{name}</h3>
          <span className="client-tag">{client}</span>
        </div>
        <div className="highlight">{highlight}</div>
        <p className="description">{description}</p>
        <div className="achievements">
          {achievements.map((achievement, index) => (
            <div key={index} className="achievement-item">
              <span className="check-icon">✓</span>
              {achievement}
            </div>
          ))}
        </div>
        <button className="read-more">Read Full Case Study</button>
      </div>
    </div>
  );
};

export default CaseStudies;