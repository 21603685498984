import React from "react";
import "./Contact.css";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';

const ContactFormURL = 'https://5lh9umqz6j.execute-api.eu-west-1.amazonaws.com/prod/';

const Contact = () => {
    return (
        <div id="contact" className="main-content">
            <div className="contact-container">
                <div className="left-section">
                    <h1>Contact</h1>
                    <h2>Ready to begin your cloud evolution?</h2>
                    <p>
                        Leave your message and we'll get back to you shortly. 
                        You can also reach us anytime via <a href="mailto:contact@tysonworks.com">contact@tysonworks.com</a> or 
                        follow our official social media channels to stay connected.
                    </p>
                    <div className="social-links">
                        <a href="https://www.linkedin.com/company/tysonworks" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-linkedin"></i>
                        </a>
                        <a href="https://github.com/tysonworks" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-github"></i>
                        </a>
                        <a href="https://www.youtube.com/tysonworks" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-youtube"></i>
                        </a>
                    </div>
                </div>
                <div className="right-section">
                    {ContactForm()}
                </div>
            </div>
        </div>
    );
};

const ContactForm = () => {
    return (
        <Formik
            initialValues={{
                name: "",
                email: "",
                message: ""
            }}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Name is required'),
                email: Yup.string().email('Email is invalid').required('Email is required'),
                message: Yup.string().required('Message is required')
            })}
            onSubmit={async (fields, { setSubmitting, resetForm }) => {
                try {
                    const response = await fetch(ContactFormURL, {
                        method: 'POST',
                        body: JSON.stringify({
                            name: fields.name,
                            email: fields.email,
                            message: fields.message
                        }),
                    });

                    if (response.ok) {
                        Toastify({
                            text: "Thank you for reaching out! Our team will contact you shortly.",
                            duration: 5000,
                            gravity: "top",
                            position: "center",
                            style: {
                                background: "#4CAF50",
                                color: "white",
                                padding: "1rem",
                                borderRadius: "4px"
                            }
                        }).showToast();
                        resetForm();
                    } else {
                        throw new Error('Submission failed');
                    }
                } catch (error) {
                    Toastify({
                        text: "Message failed to send. Please try again.",
                        duration: 5000,
                        gravity: "top",
                        position: "right",
                        style: {
                            background: "#f44336",
                            color: "white",
                            padding: "1rem",
                            borderRadius: "4px"
                        }
                    }).showToast();
                } finally {
                    setSubmitting(false);
                }
            }}
        >
            {({ errors, touched, isSubmitting }) => (
                <Form>
                    <div className="form-group">
                        <label>Name *</label>
                        <Field name="name" type="text" className={'form-control' + (errors.name && touched.name ? ' is-invalid' : '')} />
                        <ErrorMessage name="name" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label>Email *</label>
                        <Field name="email" type="email" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
                        <ErrorMessage name="email" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <label>Message *</label>
                        <Field name="message" as="textarea" className={'form-control' + (errors.message && touched.message ? ' is-invalid' : '')} />
                        <ErrorMessage name="message" component="div" className="invalid-feedback" />
                    </div>
                    <div className="form-group">
                        <button type="submit" className="submit-button" disabled={isSubmitting}>
                            Submit
                        </button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default Contact;
