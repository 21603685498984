import React from "react";
import "./Company.css";
import ABOUT_IMAGE from "../../assets/images/about-image.png";

const Company = () => {
  return (
    <div id="company">
      <div id="company-top" className="grid section">
        <div className="col-12_sm-12">
          <h2 className="head">Building the Future of Cloud Infrastructure</h2>
          <h3 className="subhead">Your Trusted AWS Partner Since 2022</h3>
        </div>
        <div className="col-12_sm-12">
          <div className="content-wrapper">
            <div className="text-section">
              <p className="body highlight">
                We help startups and growing businesses harness the full power of AWS cloud without the complexity and overhead.
              </p>
              <p className="body">
                With over a decade of combined AWS expertise, we've helped dozens of companies scale their cloud infrastructure while keeping costs under control. Our team of certified AWS experts brings enterprise-grade cloud management practices to growing businesses.
              </p>
              <p className="body">
                Whether you're launching a new product, scaling your infrastructure, or optimizing costs, we provide the expertise you need to succeed in the cloud. Our hands-on approach means you get practical solutions, not just theoretical advice.
              </p>
              <div className="stats-grid">
                <div className="stat-item">
                  <span className="stat-number">50+</span>
                  <span className="stat-label">Successful Projects</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">$2M+</span>
                  <span className="stat-label">Client Savings</span>
                </div>
                <div className="stat-item">
                  <span className="stat-number">24/7</span>
                  <span className="stat-label">Support Coverage</span>
                </div>
              </div>
            </div>
            <div className="image-section">
              <img className="about-image" alt="TysonWorks cloud expertise visualization" src={ABOUT_IMAGE} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Company;