import React from "react";
import AWS_IMAGE from "../../assets/images/aws.svg";
import SLS_IMAGE from "../../assets/images/sls.svg";
import DEVOPS_IMAGE from "../../assets/images/devops.svg";
import MICROSERVICES_IMAGE from "../../assets/images/microservices.svg";
import FINOPS_IMAGE from "../../assets/images/finops.png";
import COMPLIANCE_IMAGE from "../../assets/images/compliance.svg";
import CLOUD_TRAINING_IMAGE from "../../assets/images/cloud-training.png";
import "./Offerings.css";

const Services = (props) => {
    const servicesData = [
        {
            id: 1,
            image: DEVOPS_IMAGE,
            title: "Quick AWS Solutions",
            description: "Same-day expert help with your AWS challenges. Pay only for what you need - perfect for startups watching their budget.",
            type: "onDemand"
        },
        {
            id: 2,
            image: FINOPS_IMAGE,
            title: "Cost-Effective Projects",
            description: "Fixed-price AWS projects with clear ROI. Migrations, cost-cutting, or performance optimization - know exactly what you'll get and pay.",
            type: "onDemand"
        },
        {
            id: 3,
            image: COMPLIANCE_IMAGE,
            title: "Fast-Track Compliance",
            description: "Get SOC2 & HIPAA ready in weeks, not months. Streamlined process that won't slow down your startup's momentum.",
            type: "onDemand"
        },
        {
            id: 4,
            image: CLOUD_TRAINING_IMAGE,
            title: "Rapid Team Training",
            description: "Quick, focused AWS training for your team. Learn exactly what you need to know to scale your startup efficiently.",
            type: "onDemand"
        },
        {
            id: 6,
            image: AWS_IMAGE,
            title: "Startup Launchpad",
            subtitle: "Perfect for early-stage startups",
            points: [
                "Full AWS Infrastructure Setup & Management",
                "Continuous Cost Optimization",
                "Slack & Email Support (12hr response)",
                "Monthly Architecture Reviews",
            ],
            price: 5000,
            type: "monthly",
            popular: false
        },
        {
            id: 7,
            image: AWS_IMAGE,
            title: "Scale-Up Accelerator",
            subtitle: "Most popular for growing startups",
            points: [
                "Everything in Startup Launchpad +",
                "24/7 Infrastructure Monitoring",
                "Priority Support (4hr response)",
                "Weekly Architecture Reviews",
            ],
            price: 10000,
            type: "monthly",
            popular: true
        },
        {
            id: 8,
            image: AWS_IMAGE,
            title: "Growth Engine",
            subtitle: "For scale-up ready companies",
            points: [
                "Everything in Scale-Up Accelerator +",
                "Dedicated Slack Channel",
                "Direct Phone Support",
                "Custom SLA Coverage",
                "Dedicated Engineer",
            ],
            price: 15000,
            type: "monthly",
            popular: false
        }
    ];

    // Map through each data array to create service items
    const monthlyServiceItems = servicesData.filter(x => x.type === "monthly").map(data => <ServiceItem {...data} />);
    const serviceItems = servicesData.filter(x => x.type === "onDemand").map(data => <ServiceItem {...data} />);

    return (
        <div id="offering" className="grid section">
            {servicesSection(
                "monthly-services",
                "Monthly Packages",
                "Want to minimize DevOps costs and make sure your Cloud infrastructure at the hand of our experts?",
                monthlyServiceItems
            )}
            {servicesSection(
                "services",
                "Consulting & Training Services",
                "Discover our custom consulting solutions and training services for your cloud needs.",
                serviceItems
            )}
        </div>
    )
}

const servicesSection = (sectionId, title, description, serviceItems) => {
    return (
        <div className="grid section" id={sectionId}>
            <div className="col-12">
                <h2 className="headline">{title}</h2>
                {sectionId === "monthly-services" ? (
                    <p className="body" style={{ textAlign: 'center', margin: '0 auto 40px auto', maxWidth: '800px' }}>
                        Save 50-70% vs hiring in-house AWS experts. Get enterprise-grade cloud management at startup-friendly prices.
                    </p>
                ) : (
                    <p className="body" style={{ textAlign: 'center', margin: '0 auto 40px auto', maxWidth: '800px' }}>
                        Need AWS help right now? Get expert solutions without the overhead. Pay only for what you need, when you need it.
                    </p>
                )}
            </div>
            <div className="col-12 services-grid">
                {serviceItems}
            </div>
        </div>
    );
};

const ServiceItem = ({ id, image, title, subtitle, description, points, price, type, popular }) => {
    const pointStyle = {
        margin: '5px 0',
        textAlign: 'center'
    };
    
    const formattedPrice = price && price.toLocaleString();
    const className = `feature ${popular ? 'popular' : ''}`;

    return (
        <div className={className} key={id}>
            {popular && <div className="popular-badge">Most Popular</div>}
            <div className="image"><img alt={title} src={image} /></div>
            <div className="headline">{title}</div>
            {subtitle && <div className="subtitle">{subtitle}</div>}
            <div className="body">{description}</div>
            {points && points.map((point, index) => (
                <p className="body" key={`${id}-${index}`} style={pointStyle}>{point}</p>
            ))}
            {price && (
                <div className="pricing">
                    <div className="body" style={{ marginBottom: '20px' }}>Starting from <b>${formattedPrice}</b> per month</div>
                    <a href="/contact" className="cta-button">Get Started</a>
                </div>
            )}
        </div>
    )
}

export default Services;