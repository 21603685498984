import React from "react";
import Splash from "../../components/splash/Splash";
import Services from "../../components/services/Services";
import News from "../../components/news/News";
import Customers from "../../components/customers/Customers";
import CaseStudies from "../../components/casestudies/CaseStudies";
import WhyChooseTysonWorks from "../../components/whychoosetysonworks/WhyChooseTysonWorks";
import ScrollReveal from '../../components/scroll/ScrollReveal';


const Home = (props) => {
    return (
        <React.Fragment>
            <ScrollReveal delay={200}>
                <Splash />
            </ScrollReveal>
            <Services />
            <ScrollReveal delay={200}>
                <Customers />
            </ScrollReveal>
            <ScrollReveal delay={200}>
                <News />
            </ScrollReveal>
            <br />
            <br />
        </React.Fragment>
    )
}

export default Home;