import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ScrollReveal.css';

const ScrollReveal = ({ children, delay = 0 }) => {
  const revealRef = useRef(null);

  useEffect(() => {
    const revealElement = revealRef.current;
    const handleScroll = () => {
      const revealTop = revealElement.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;
      if (revealTop < windowHeight) {
        revealElement.classList.add('reveal-visible');
        window.removeEventListener('scroll', handleScroll);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div
      ref={revealRef}
      className="reveal"
      style={{ transitionDelay: `${delay}ms` }}
    >
      {children}
    </div>
  );
};

ScrollReveal.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
};

export default ScrollReveal;