import React from "react";
import "./WhyChooseTysonWorks.css";

const WhyChooseTysonWorks = () => {
  return (
    <div id="why-choose-tysonworks" className="grid section">
      <div className="col-12_sm-12">
        <h2 className="head">Why Fast-Growing Companies Choose Us</h2>
        <p className="subtitle">Get enterprise-grade AWS expertise without the enterprise price tag</p>
      </div>
      <div className="benefits-grid">
        <div className="benefit-item">
          <div className="benefit-icon">🚀</div>
          <h3>Rapid Response</h3>
          <p>Get same-day expert help when you need it. Our team is always ready to tackle your AWS challenges.</p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">💰</div>
          <h3>Cost Optimization</h3>
          <p>Our clients save 40-60% on their AWS bills through our proven optimization techniques.</p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">🛡️</div>
          <h3>Security First</h3>
          <p>Enterprise-grade security practices and compliance expertise (SOC2, HIPAA) built into everything we do.</p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">📈</div>
          <h3>Scale Ready</h3>
          <p>Infrastructure that grows with you. From prototype to production to scale, we've got you covered.</p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">🤝</div>
          <h3>Dedicated Support</h3>
          <p>Direct access to AWS experts who know your infrastructure inside and out.</p>
        </div>
        <div className="benefit-item">
          <div className="benefit-icon">⚡</div>
          <h3>Modern Stack</h3>
          <p>Expertise in cutting-edge AWS services and best practices for maximum efficiency.</p>
        </div>
      </div>
      <div className="cta-section">
        <p className="cta-text">Ready to transform your cloud infrastructure?</p>
        <a href="/contact" className="cta-button">Schedule a Free Consultation</a>
      </div>
    </div>
  );
};

export default WhyChooseTysonWorks;
